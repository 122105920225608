import React, { useState, useEffect, useRef, useCallback } from "react";
import NavigationBar from "./components/NavigationBar";
import "./App.css";
import "./Variables.css";
import Home from "./pages/Home";
import Cirriculum from "./pages/Cirriculum";
import content from './content.json';
import Pricing from "./pages/Pricing";
import "./Mobile.css";
import Grading from "./pages/grading";

function App() {
  const [selectedButton, setSelectedButton] = useState(0);
  const [circlePosition, setCirclePosition] = useState(window.innerWidth > 660 ? { x: 25, y: -12 } : { x: 50, y: 50 });

  const [opacity, setPageOpacity] = useState({
    pageOne: 100,
    pageTwo: 0,
    pageThree: 0,
    pageFour: 0,
    pageFive: 0
  });

  const scrollRef = useRef();
  const appRef = useRef();

  // const resize = () => {
  //   if(window.innerWidth < 660) {
  //     setMobile(true);
  //   } else {
  //     setMobile(false);
  //   }
  // }

  const setCircle = (size, size2) => {
    if(window.innerWidth > 660) {
      setCirclePosition(size);
    } else {
      setCirclePosition(size2);
    }
  }

  const NextCircle = (props) => {
    return(
      <div
        className={`nextCircle nextPage${props.index + 1}`}
        onClick={() => !props.link ? handleButtonClick(props.index + 1, true) : window.location.assign(props.link)}
        style={props.style}
      >
        <div className="content">
          {props.children}
          <div className="arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000"
              version="1.1"
              viewBox="0 0 330 330"
              xmlSpace="preserve"
            >
              <path d="M250.606 154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213.001-5.857 5.858-5.857 15.355.001 21.213l139.393 139.39L79.393 304.394c-5.857 5.858-5.857 15.355.001 21.213C82.322 328.536 86.161 330 90 330s7.678-1.464 10.607-4.394l149.999-150.004a14.996 14.996 0 000-21.213z"></path>
            </svg>
          </div>
        </div>
      </div>
    )
  }

  const handleButtonClick = useCallback((index, force=false) => {
    const defaultOpacity = {
      pageOne: 0,
      pageTwo: 0,
      pageThree: 0,
      pageFour: 0,
      pageFive: 0,
    }
    setSelectedButton(index);
    scrollRef.linepos = (index * 20);
    scrollRef.buttonIndex = index;
    // Update circle position based on button index
    if(force) {
      const element = document.querySelectorAll(`.container`)[index || 0];
      if(element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    else if (index === 0) {
      setPageOpacity({
        ...defaultOpacity
      });

      setTimeout(() => {
        setPageOpacity({
          ...defaultOpacity,
          pageOne: 100
        });
      }, 200);

      setCircle({ x: 25, y: -12 }, { x: 50, y: 50 });
    } else if (index === 1) {
      setPageOpacity({
        ...defaultOpacity
      });

      setTimeout(() => {
        setPageOpacity({
          ...defaultOpacity,
          pageTwo: 100
        });
      }, 200); // 300 milliseconds delay

      setCircle({ x: -8.5, y: -16 }, { x: 30, y: 35 });
    } else if (index === 2) {
      setPageOpacity({
        ...defaultOpacity
      });
      setCircle({ x: -45, y: -10 }, { x: 44, y: 60 });

      setTimeout(() => {
        setPageOpacity({
          ...defaultOpacity,
          pageThree: 100
        });
      }, 200); // 300 milliseconds delay
    } else if (index === 3) {
      setPageOpacity({
        ...defaultOpacity
      });
      setCircle({ x: 25, y: 10 }, { x: 32, y: 68 });

      setTimeout(() => {
        setPageOpacity({
          ...defaultOpacity,
          pageFour: 100,
        });
      }, 200); // 300 milliseconds delay
    } else if (index === 4) {
      setPageOpacity({
        ...defaultOpacity
      });
      setCircle({ x: 25, y: -15.5 }, { x: 50, y: 50 });

      setTimeout(() => {
        setPageOpacity({
          ...defaultOpacity,
          pageFive: 100,
        });
      }, 200); // 300 milliseconds delay
    }
  }, []);

  useEffect(() => {
    const ref = appRef.current;

    const handleScroll = (e) => {
      e.preventDefault();
      let scrollAmount = e.deltaY || 0;
      const newUnderlinePosition = Math.min(
        Math.max(0, (scrollRef?.underlinePosition || 0) + scrollAmount * 0.1),
        80
      );

      scrollRef.underlinePosition = newUnderlinePosition
      const height = window.innerHeight < 700 ? window.innerHeight + 100 : window.innerHeight;
      const interval = height - 100;
      const pos = appRef.current?.scrollTop;
      scrollRef.position = pos;
      const buttonIndex = Math.floor(scrollRef.position / interval);
      console.log(buttonIndex)
      if(scrollRef.prev !== undefined && scrollRef.prev !== buttonIndex) {
        handleButtonClick(buttonIndex);
      }
      scrollRef.prev = buttonIndex;
    };

    ref?.addEventListener("scroll", handleScroll);

    return () => {
      ref?.removeEventListener("scroll", handleScroll);
    };
  }, [handleButtonClick])

  return (
    <div className="App" ref={appRef}>
      <div className="mirror-content">
        <NavigationBar
          enrollLink={content.enrollLink}
          selectedButton={selectedButton}
          onButtonClick={handleButtonClick}
        />
      </div>
      <div
        className="selected-line"
        style={{
          left: `${scrollRef.linepos || 0}%`,
          width: "20%",
        }}
      ></div>
      <div
        className={`circle circlePage${(scrollRef.buttonIndex ?? 0) + 1}`}
        style={{
          transform: `translate(${circlePosition.x}vw, calc(${circlePosition.y}vh - ${scrollRef.y || 0}px))`,
          transition: "transform 0.5s ease",
        }}
      ></div>

      <Home
        style={{
          opacity: opacity.pageOne,
          transition: "opacity 0.3s ease",
        }}
        next={
          <NextCircle
            index={0}
            style={{ top: '78vh', left: '30vw' }}
          >
            Checkout our cirriculum
          </NextCircle>
        }
        content={{ ...content.home, enrollLink: content.enrollLink }}
      />

      <Cirriculum
        content={{...content.cirriculum }}
        style={{
          opacity: opacity.pageTwo,
        }}
        next={
          <NextCircle
            index={1}
            style={{ top: '85vh', left: '36.1vw' }}
          >
            See how we grade
          </NextCircle>
        }
      />

      <Grading
        content={content.grading}
        style={{
          opacity: opacity.pageThree
        }}
        next={
          <NextCircle
            index={2}
            style={{ top: '85vh', left: '42vw' }}
          >
            How are we priced?
          </NextCircle>
        }
      />

      <Pricing
        content={content.pricing}
        shown={scrollRef.buttonIndex === 3}
        style={{
          opacity: opacity.pageFour,
        }}
        next={
          <NextCircle
            link={content.enrollLink}
            style={{ top: '78vh', left: '35.5vw' }}
          >
            Enroll now!
          </NextCircle>
        }
      />
    
      <div
        className='container end'
        style={{
          opacity: opacity.pageFive,
          transition: "opacity 0.3s ease",
        }}
      >
        <div className='page page5'>
          <h1>{content.endPage}</h1>
          <br></br>
          <br></br>
          <a href={content.enrollLink} className="sign-up-button">
            Enroll Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
