import './Grading.css';

const Grading = ({ content, next, style}) => {
  return(
    <div className='container' style={style}>
      <div
        className="page page3"
      >
        <div
          className="image"
        ></div>
        <div
          className='content'
        >
          <h1>
            <span>Grading</span>.
          </h1>
          <div className='paragraph'>
            <br></br>
            {content.about}
          </div>
        </div>
      </div>
      {next}
    </div>
  )
}

export default Grading;