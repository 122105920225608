import "./InformationBox.css";

const InformationBox = ({ title, text, toggleText, color, style, select }) => {
  return (
    <div
      className={`info-box`}
      onClick={() => select()}
      style={style}
    >
      <div className="info-title">
        {title}
      </div>
      <div className="info-text">{text}</div>
    </div>
  );
};

export default InformationBox;
