import './Pricing.css';

const Pricing = ({ style, next, content, shown }) => {
  return(
    <div
      className='container'
      style={style}
    >
      <div className="page page4">
        <h1>
          <span>Pricing</span>.
        </h1>
        <br></br>
        <div className="pricing">
          {shown &&
            <div className='bottomShadow'></div>
          }
          <div>
            <div className="price">
              <h2>${content.price}</h2>
              <span>/month</span>
            </div>
            <br></br>
            <h3>Includes</h3>
            <ul>
              {content.includes.map((item, i) =>
                <li key={i}>{item}</li>
              )}
            </ul>
          </div>
          <div className='rightPane'>
            <h2>That's</h2>
            <br></br>
            {content.lessThan.map((item, i) =>
              <div className='percents' key={i}>
                <h4>{item[0]}:&nbsp;</h4>
                <span>{item[1]}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {next}
    </div>
  )
}

export default Pricing;