import React, { useState } from "react";
import "./NavigationBar.css";

function NavigationBar({ selectedButton, onButtonClick, enrollLink }) {
  const [show, setShow] = useState(false);

  const click = (index) => {
    setShow(false);
    onButtonClick(index, true)
  }

  return (
    <>
      <button
        type='button'
        className={`menuButton${show ? ' show' : ''}`}
        onClick={() => setShow(!show)}
      >
        <svg viewBox="0 0 100 80" width="2rem" height="3rem">
          <rect width="100" height="0.6rem"></rect>
          <rect y="30" width="100" height="0.6rem"></rect>
          <rect y="60" width="100" height="0.6rem"></rect>
        </svg>
      </button>
      <div className={`navigation-bar${show ? ' show' : ''}`}>
        <div
          className={`nav-button ${selectedButton === 0 ? "selected" : ""}`}
          onClick={() => click(0, true)}
        >
          Agoro
        </div>
        <div
          className={`nav-button ${selectedButton === 1 ? "selected" : ""}`}
          onClick={() => click(1, true)}
        >
          Curriculum
        </div>
        <div
          className={`nav-button ${selectedButton === 2 ? "selected" : ""}`}
          onClick={() => click(2, true)}
        >
          Grading
        </div>
        <div
          className={`nav-button ${selectedButton === 3 ? "selected" : ""}`}
          onClick={() => click(3)}
        >
          Pricing
        </div>
        <div className="nav-button">
          <a href={enrollLink} className="sign-up-button">
            Enroll Now
          </a>
        </div>
        <div className="line"></div>
      </div>
    </>
  );
}

export default NavigationBar;
