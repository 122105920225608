import { useState } from "react";
import InformationBox from "../components/InformationBox";
import "./Cirriculum.css";

const Cirriculum = ({ style, next, content }) => {
  const [panel, setPanel] = useState(0);
  const [selected, setSelected] = useState(undefined);

  return(
    <div
      className="container"
      style={style}
    >
      <div className='page page2'>
        <h1>
          <span>Curriculum</span>.
        </h1>
        <div className='sections'>
          {content.examples.length > panel &&
            <button className='nextBtn' onClick={() => setPanel(panel + 1)}>Examples ▶</button>
          }
          {panel > 0 &&
            <button className='nextBtn prev' onClick={() => setPanel(panel - 1)}>◀ Cirriculum</button>
          }
          <div className={`overlay ${ selected?.show ? ' show' : '' }`}>
            <div>
              <h2>{selected?.title}</h2>
              <br></br>
              <div className="body">{selected?.info}</div>
            </div>
            <button className='close' onClick={() => setSelected({ ...selected, show: false })}>
              Close
              <svg viewBox="0 0 100 80" width="2rem" height="3rem">
                <rect width="100" height="0.6rem"></rect>
                <rect y="60" width="100" height="0.6rem"></rect>
              </svg>
            </button>
          </div>
          <div
            className="page-two-grid"
            style={{
              ...(panel !== 0 ? {
                transform: 'translateX(-100%)',
              } : {})
            }}
          >
            {content.cirriculum.map((about, i) => 
              <InformationBox
                key={i}
                select={() => setSelected({ ...about, show: true })}
                title={about?.title}
                text={`Phase ${i + 1}`}
                toggleText={about?.info}
              />
            )}
          </div>
          {content.examples.map((example, i) =>
            <div
              key={i}
              className="page-two-grid t"
              style={{
                ...(panel !== 0 ? {
                  transform: 'translateX(-100%)',
                } : {})
              }}
            >
              {example.map((about, j) => 
                <InformationBox
                  key={j}
                  select={() => setSelected({ ...about, show: true })}
                  title={about?.title}
                  text={`Phase ${i + 1}`}
                  toggleText={about?.info}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <button
        className='nextBtn majors'
        onClick={() => setSelected({
          ...content.majors,
          show: true,
          info: (
            <div className="majors">
              {content.majors.info.map((school, i) =>
                <div className="major" key={i}>
                  <h3>{school.title}</h3>
                  {school.majors.map((major, j) =>
                    <>
                      <h4>{major[0]}</h4>
                      {major[1] && <p>{major[1]}</p>}
                    </>  
                  )}
                </div>
              )} 
            </div>
          )
        })}
      >
        See Majors 📃
      </button>
      {next}
    </div>
  )
}

export default Cirriculum;